import styled from 'styled-components'

type NormalImageProps = {
  objectFit?: React.CSSProperties['objectFit']
  objectPosition?: React.CSSProperties['objectPosition']
}

type NormalImageWrapperProps = {
  width?: React.CSSProperties['width']
  height?: React.CSSProperties['height']
}

export const ImageInner = styled.img<NormalImageProps>`
  max-width: 100%;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  max-height: ${(props) => (props.height ? `${props.height}px` : '100%')};
  object-fit: ${(props) => props.objectFit};
  object-position: ${(props) => props.objectPosition};
`

export const ImageWrapper = styled.div<NormalImageWrapperProps>`
  max-width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  max-height: ${(props) => (props.height ? `${props.height}px` : '100%')};
`
